import { default as change8AqhybpfssMeta } from "/tmp/build_1ed58ab8/pages/account/change.vue?macro=true";
import { default as indexa8JEfQr28FMeta } from "/tmp/build_1ed58ab8/pages/account/index.vue?macro=true";
import { default as loginnmXQmY0lqlMeta } from "/tmp/build_1ed58ab8/pages/account/login.vue?macro=true";
import { default as _91token_93z4BnOgt89rMeta } from "/tmp/build_1ed58ab8/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completeS2XGj7PkDeMeta } from "/tmp/build_1ed58ab8/pages/account/password-reset/complete.vue?macro=true";
import { default as doneLyCOu307j8Meta } from "/tmp/build_1ed58ab8/pages/account/password-reset/done.vue?macro=true";
import { default as indexU7imgoBWUKMeta } from "/tmp/build_1ed58ab8/pages/account/password-reset/index.vue?macro=true";
import { default as password_45reset5F52T3fxQMMeta } from "/tmp/build_1ed58ab8/pages/account/password-reset.vue?macro=true";
import { default as passwordpf9bTxq3JMMeta } from "/tmp/build_1ed58ab8/pages/account/password.vue?macro=true";
import { default as registerodqrtSiWvbMeta } from "/tmp/build_1ed58ab8/pages/account/register.vue?macro=true";
import { default as contact7ebgRCBJYwMeta } from "/tmp/build_1ed58ab8/pages/contact.vue?macro=true";
import { default as cookie_45policyMKvkFCKmrZMeta } from "/tmp/build_1ed58ab8/pages/docs/cookie-policy.vue?macro=true";
import { default as privacy_45policyxKAoHdCLpAMeta } from "/tmp/build_1ed58ab8/pages/docs/privacy-policy.vue?macro=true";
import { default as terms_45of_45usekhgNBg1EFoMeta } from "/tmp/build_1ed58ab8/pages/docs/terms-of-use.vue?macro=true";
import { default as _91slug_9337kXOneMJvMeta } from "/tmp/build_1ed58ab8/pages/events/[slug].vue?macro=true";
import { default as indexjQZKHnCy8iMeta } from "/tmp/build_1ed58ab8/pages/events/index.vue?macro=true";
import { default as indexEoo6bmNqRjMeta } from "/tmp/build_1ed58ab8/pages/index.vue?macro=true";
import { default as _91id_93dE3WJF6HyUMeta } from "/tmp/build_1ed58ab8/pages/orders/[id].vue?macro=true";
import { default as indexEdZq8dSBcJMeta } from "/tmp/build_1ed58ab8/pages/orders/index.vue?macro=true";
import { default as indexE2YeWbC7FxMeta } from "/tmp/build_1ed58ab8/pages/purchase/index.vue?macro=true";
export default [
  {
    name: "account-change",
    path: "/account/change",
    meta: change8AqhybpfssMeta || {},
    component: () => import("/tmp/build_1ed58ab8/pages/account/change.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexa8JEfQr28FMeta || {},
    component: () => import("/tmp/build_1ed58ab8/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginnmXQmY0lqlMeta || {},
    component: () => import("/tmp/build_1ed58ab8/pages/account/login.vue")
  },
  {
    name: password_45reset5F52T3fxQMMeta?.name,
    path: "/account/password-reset",
    meta: password_45reset5F52T3fxQMMeta || {},
    component: () => import("/tmp/build_1ed58ab8/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/tmp/build_1ed58ab8/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/tmp/build_1ed58ab8/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/tmp/build_1ed58ab8/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/tmp/build_1ed58ab8/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordpf9bTxq3JMMeta || {},
    component: () => import("/tmp/build_1ed58ab8/pages/account/password.vue")
  },
  {
    name: "account-register",
    path: "/account/register",
    meta: registerodqrtSiWvbMeta || {},
    component: () => import("/tmp/build_1ed58ab8/pages/account/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_1ed58ab8/pages/contact.vue")
  },
  {
    name: "docs-cookie-policy",
    path: "/docs/cookie-policy",
    component: () => import("/tmp/build_1ed58ab8/pages/docs/cookie-policy.vue")
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/tmp/build_1ed58ab8/pages/docs/privacy-policy.vue")
  },
  {
    name: "docs-terms-of-use",
    path: "/docs/terms-of-use",
    component: () => import("/tmp/build_1ed58ab8/pages/docs/terms-of-use.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/tmp/build_1ed58ab8/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/tmp/build_1ed58ab8/pages/events/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_1ed58ab8/pages/index.vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93dE3WJF6HyUMeta || {},
    component: () => import("/tmp/build_1ed58ab8/pages/orders/[id].vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexEdZq8dSBcJMeta || {},
    component: () => import("/tmp/build_1ed58ab8/pages/orders/index.vue")
  },
  {
    name: "purchase",
    path: "/purchase",
    component: () => import("/tmp/build_1ed58ab8/pages/purchase/index.vue")
  }
]